import metamask from "../images/metamask.png";
import walletconnect from "../images/walletconnect.png";
import binance from "../images/binance.png";
import coinbase from "../images/coinbase.png";
import { coinbaseWallet } from "wagmi/connectors";

export const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'dev'

export const serverUrl = process.env.REACT_APP_DEV_MODE === 'dev' ? 'http://localhost:4000/api' : process.env.REACT_APP_BASE_API_URL;
export const secureToken = "BLK-lGin834iN";


export const PINATA_GATEWAY = process.env.REACT_APP_PINATA_GATEWAY;

export const GC_BUCKET_URL = process.env.REACT_APP_GC_BUCKET_URL;

// export const auth = "Basic " + Buffer.from(`${process.env.REACT_APP_PROJECT_ID}` + ":" + `${process.env.REACT_APP_SECRET_KEY}`).toString("base64");

export const CREATER_ROYALTY = 250;

export const NFT_ROYALTY_PERCENT = 0.15;

export const RPC = {
  MUMBAI: process.env.REACT_APP_MUMBAI_RPC,
  POLYGON: process.env.REACT_APP_POLYGON_RPC,
  AMOY: process.env.REACT_APP_AMOY_RPC
};

export const PINATA_JWT = process.env.REACT_APP_PINATA_JWT;

export enum USER_ROLE {
  VISITOR = 'visitor',
  USER = 'user',
  ADMIN = 'admin',
}

export enum NFT_STATUS {
  MINT = "mint",
  VERIFY = 'verify',
  SALE = "sale",
  INACTIVE = "inactive"
}

export const LISTING = {
  START: 0,
  EDIT: 1,
  APPROVING: 5,
  CANCELING: 6,

  LIST_FINISH: 8,
  EDIT_FINISH: 9,
  CANCEL_FINISH: 10,
};

export enum ORDER_STATUS {
  SALE = "sale",
  SOLD = "sold",
  CANCEL = "canceled",
}

export const FILTER_FIELD = {
  NEW: 0,
  RESALE: 1,
  VIDEO: 2,
  AUDIO: 3,
  PRICE_MIN: 4,
  PRICE_MAX: 5,
};

export const FILE_TYPE = {
  VIDEO: 'video',
  AUDIO: 'audio',
}

export const WalletsIcons = {
  metaMask: metamask,
  coinbaseWallet: coinbase,
  walletConnect: walletconnect,
  binance: binance,
}

export enum SubscriptionStatus {    
  APPROVED = 'APPROVED',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}

export const CATEGORIES = ["Book", "Film", "Reality"];