import { useState, useEffect, useCallback, useContext } from "react";
import { get } from "../utils/fetchApis";
import { AppContext } from "src/Context";

function useFaqList(pagination) {
  const [faqList, setFaqList] = useState([]);
  const [count, setCount] = useState()
  const [isLoading, setIsLoading] = useState(false);    
  const {setAlertState} = useContext(AppContext)   
  
  const getFaqList = useCallback(async () => {    
    setIsLoading(true);
    const {page, size, search} = pagination
    
    const {data, success, message}  = await get("faqs", {page, size, search});
    
    if (!success) {
        setAlertState({
            open: true,
            message,
            serverity: 'error',
        })
        return
    }
    
    setCount(data.totalResults)
    setFaqList(data.items);    
    setIsLoading(false);
    
  }, [pagination]);

  const refetch = () => {
    getFaqList()
  }

  useEffect(() => {            
    getFaqList();
  }, [pagination]);

  
  
  return {faqList, count, refetch, isLoading};
}

export default useFaqList;
