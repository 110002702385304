import { Box, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { GreenButton } from "src/components/Styled"

export default function NotFound() {
    const navigate = useNavigate()
    return (
        <Box py={10} px={2} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
            
            <img src={'assets/images/404.svg'}/>
            
            <Typography variant="h2" py={3}>Oops! Page Not Found</Typography>
            <Typography variant="body2" textAlign="center">We couldn't find the page you're looking for. It might have been moved or no longer exists.</Typography>
            <Typography variant="body2" textAlign={"center"}>Let’s get you back on track! Try heading to our homepage or use the menu to navigate.</Typography>

            <Stack pt={3}>
                <GreenButton onClick={_ => navigate('/')}>Back to homepage</GreenButton>
            </Stack>            
        </Box>
    )
}