import { useRef, useEffect } from "react";
import { Box, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function Tag({ name, closable, onClose }) {  
  
  return (    
    <Stack direction="row" alignItems="center" p='4px' 
        sx={{background: '#fafafa', border: '1px solid #d9d9d9', marginRight: '4px'}}>

        <Typography variant="caption">{name}</Typography> 
        {closable && <IconButton sx={{width: '16px', height:'16px', marginLeft: '3px'}} onClick={_ => onClose()}>
            <CloseIcon sx={{width: '16px'}} />
        </IconButton> }
    </Stack>    
  );
}

export default Tag;
