/* eslint-disable camelcase */
import { Box, Button, Container, Grid, IconButton, Menu, MenuItem, Stack, SvgIcon, TextField, Typography, useMediaQuery } from "@mui/material";
import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";

import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import SearchIcon from "@mui/icons-material/Search";
import { AppContext } from "../Context";
import ListStatModal from "../components/Modal/ListStatModal";
import NftItemCard from "../components/NftItemCard";
import { buttonStyle, filterOptions, sorting } from "../components/Styled/style";
import Loading from "../connectivityAssets/Loading";
import useDocumentTitle from "../useDocumentTitle";
import { approveUSDC, buyNFT, getApprovedNFT, approveNFT, cancelItemForSale, getAllowanceUSDC, getNewOrderId, getUSDCBalance, putItemForSale, updateItemForSale } from "../utils/contract";
import { post, update } from "../utils/fetchApis";
import { LISTING, NFT_STATUS, ORDER_STATUS } from "../utils/constant";
import { config } from "../utils/wagiConfig";
import { getEthersSigner } from "../utils/ether";
import { isAxiosError } from "axios";
import { styled } from "@mui/material";
import { marketplaceContractAddr } from "../connectivityAssets/mintContract/addresses";
import { useSaleNfts } from "../hooks";
import { audioSVG, listSVG, resaleSVG, startSVG, videoSVG, filterSVG } from "src/components/SVG";
import MyButton from "src/components/Buttonone";

const HeaderItem = styled(Button)({
  background: "transparent",
  color: "#2A3538",
  borderRadius: " 10px",
  padding: "4px 16px",
  minWidth: '100px',
  "&:hover": {
    backgroundColor: "#6FDA44",
    color: "black",
    transition: "1s",
  },
});

const LoadMore = styled(Button)({
  background: "#2A3538",
  color: "white",
  borderRadius: " 6px",
  padding: "4px 16px", 
  width: "200px",
  ":hover": {
    backgroundColor: "#2A3538"
  }
});

const ShopNft = () => {
  
  const [loading, setLoading] = useState(false);  
  const [pagination, setPagination] = useState({
    page: 1,
    size: 4,
    keyword: '',
    filterBy: 'all',
    sortBy: 'Date',
    orderBy: 'ASC',
    priceFrom: '',
    priceTo: '',
  })  
  
  const [nft, setNft] = useState(null);
  const [status, setStatus] = useState(LISTING.START);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const { wallet, setAlertState, isMobile } = useContext(AppContext);

  const {saleNfts, hasMore, execute} = useSaleNfts(pagination, '')
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [priceAnchorEl, setPriceAnchorEl] = useState(null);
  const [priceRange, setPriceRange] = useState({
    from: '',
    to: '',
  })
  
  const sortOpen = Boolean(sortAnchorEl);    
  const priceOpen = Boolean(priceAnchorEl)

  useDocumentTitle("View exclusive NFT protected content | Find files from upcoming artists");

  const handleLoadMore = () => {
    setPagination({...pagination, page: pagination.page + 1})
  };    

  const handleSortBtnClick = (evt) => {
    setSortAnchorEl(evt.currentTarget);
  };

  const handlePriceBtnClick = (evt) => {
    setPriceAnchorEl(evt.currentTarget)
  }

  const handleChangeSort = (name) => {    
    switch(name) {
      case 'Price (low to high)':
        setPagination({...pagination, sortBy: 'Price', orderBy: 'ASC', page: 1})
        break;
      case 'Price (high to low)':
        setPagination({...pagination, sortBy: 'Price', orderBy: 'DESC',  page: 1})
        break;
      case 'Date (newest)':
        setPagination({...pagination, sortBy: 'Date', orderBy: 'ASC',  page: 1})
        break;
      case 'Date (oldest)':
        setPagination({...pagination, sortBy: 'Date', orderBy: 'DESC',  page: 1})
        break;    
    }
    setSortAnchorEl(null)
  }   

  const handleChangeFilter = (name) => {        
    setPagination({...pagination, filterBy: name === 'all' ? '' : name, page: 1})    
  }

  const completeList = async (price) => {
    try {
      setStatus(LISTING.APPROVING);

      const signer = await getEthersSigner(config)
      const approved = await getApprovedNFT(nft.tokenId)
      if (approved !== marketplaceContractAddr) {
        await approveNFT(signer, nft.tokenId);
      }
      await putItemForSale(signer, nft.tokenId, ethers.utils.parseUnits(price, 6));

      const orderId = await getNewOrderId();

      const params = {
        marketplaceSaleId: parseInt(orderId) - 1,
        tokenId: nft.tokenId,
        price,
        status: ORDER_STATUS.SALE,
        walletId: wallet.id,
      };

      
      const {success, message } = await post(`orders/${nft.id}/create`, params);
      if (!success) {
        setAlertState({
          open: false,
          message,
          severity: "error",
        });
      }

      await execute();

      setStatus(LISTING.LIST_FINISH);
    } catch (e) {
      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const updateList = async (price) => {
    try {      
      setStatus(LISTING.APPROVING);

      const signer = await getEthersSigner(config)
      await updateItemForSale(signer, nft.order.marketplaceSaleId, ethers.utils.parseUnits(price, 6));
      const params = {        
        price,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setStatus(LISTING.EDIT_FINISH);
    } catch (e) {
      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const cancelList = async (nft) => {
    try {
      setStatus(LISTING.CANCELING);
      const signer = await getEthersSigner(config)
      await cancelItemForSale(signer, nft.order.marketplaceSaleId);
      const params = {        
        status: ORDER_STATUS.CANCEL,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setPriceModalOpen(false);
    } catch (e) {
      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const buyHandler = async (nft) => {
    try {
      setLoading(true);      
      
      const signer = await getEthersSigner(config)
      const nftPrice = ethers.utils.parseUnits(`${nft.price}`, 6);
      
      const usdcBalance = await getUSDCBalance(signer);
      
      if (nftPrice.gte(usdcBalance)) {
        setLoading(false);
        setAlertState({
          open: true,
          message: "Low USDT Balance",
          severity: "error",
        });
        return;
      }

      const allowance = await getAllowanceUSDC(signer);
      
      if (nftPrice.gt(allowance)) {        
        await approveUSDC(signer, nftPrice);
      }

      await buyNFT(signer, nft.order.marketplaceSaleId, nftPrice);
      const params = {        
        status: ORDER_STATUS.SOLD,
        walletId: wallet.id,
        marketplaceAddress: marketplaceContractAddr,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setLoading(false);

      setAlertState({
        open: true,
        message: "Purchased NFT successfully!",
      });
    } catch (e) {
      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setLoading(false);
    }
  };

  const handleApplyPriceRange = async () => {    
    setPagination({...pagination, priceFrom: priceRange.from, priceTo: priceRange.to})
  }

  const handleList = async (nft) => {
    setNft(nft);
    setStatus(nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START);
    setPriceModalOpen(true);
  };  

  const handleSearch = (e) => {
    const keyword = e.target.value

    setPagination({...pagination, keyword, page: 1})    
  };  
  
  // console.log('shop', saleNfts, hasMore)
  return (
    <Box sx={{ background: "white" }}>
      <Container>
        {loading && <Loading isLoading={loading}/>}
        <Box py="20px">
            {priceModalOpen && (
              <ListStatModal
                status={status}
                nft={nft}
                priceModalOpen={priceModalOpen}
                completeList={(price) => completeList(price)}
                updateList={(price) => updateList(price)}
                cancelList={cancelList}
                toggelModal={(_) => setPriceModalOpen(!priceModalOpen)}
              />
            )}
            
            <Box>
              <Stack direction={isMobile ? 'column':'row'} alignItems={isMobile ? "flex-start": "center"} spacing={isMobile? 1 : 0}>  
                <Stack direction="row" sx={{overflow: 'auto', maxWidth: '100%'}}>
                {filterOptions.map(item => 
                  <HeaderItem startIcon={
                    <SvgIcon>
                      {item.value === 'all' && listSVG()}
                      {item.value === 'video' && videoSVG()}
                      {item.value === 'audio' && audioSVG()}
                      {item.value === 'original' && startSVG()}
                      {item.value === 'resale' && resaleSVG()}
                    </SvgIcon>
                  } 
                  sx={{
                    backgroundColor: pagination.filterBy === item.value ? '#6FDA44' : '',
                    whiteSpace: 'nowrap',                              
                    fontSize: isMobile ? '12px' : '14px',                    
                  }}                  
                  onClick={_ => handleChangeFilter(item.value)}
                  >{item.label}</HeaderItem>
                )}                
                </Stack>
                
                <Stack ml={isMobile ? '' : 'auto'}>
                  <TextField
                    onChange={handleSearch}  
                    placeholder="Search Keyword"
                    size="small"          
                    sx={{
                      borderRadius: "8px",
                      Input: {
                        borderRadius: "8px",
                        color: "#2A3538",
                        "&::placeholder": {
                          textOverflow: "ellipsis !important",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#2A3538",
                        },
                      },
                      backgroundColor: "#ffffff",
                      marginRight: '20px',                      
                      float: 'right',
                    }}
                    InputProps={{
                      startAdornment: (
                        <IconButton>
                          <SearchIcon sx={{ color: "#2A3538" }} />
                        </IconButton>
                      ),
                    }}
                  />                
                </Stack>
                
                <Stack direction="row">
                  <Button
                      id="demo-customized-button"                      
                      disableElevation
                      onClick={handleSortBtnClick}
                      endIcon={pagination.orderBy === 'ASC' ? <NorthIcon/> : <SouthIcon />}
                      disableRipple
                      sx={{...buttonStyle}}
                    >
                      {pagination.sortBy}                        
                  </Button>
                  <Menu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={sortAnchorEl}
                      open={sortOpen}
                      onClose={(_) => setSortAnchorEl(null)}
                    >
                      {sorting.map((name, i) => {
                        return (
                          <MenuItem
                            key={i}
                            onClick={_ => handleChangeSort(name)}
                            sx={{ color: "black" }}
                            disableRipple
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                  <Button
                    endIcon={<SvgIcon>{filterSVG()}</SvgIcon>}
                    onClick={handlePriceBtnClick}
                  >                    
                  </Button>
                  <Menu
                    id="demo-customized-menu"                      
                    anchorEl={priceAnchorEl}
                    open={priceOpen}
                    onClose={(_) => setPriceAnchorEl(null)}                      
                  >
                    <Stack className="p-3" 
                      sx={{
                        width: '300px'
                      }}>
                      <Typography variant="h2">Filter</Typography>
                      <Typography variant="h4" mt="4px">Price - USDT</Typography>
                      <Stack direction="row" alignItems="center" my="8px">
                        <TextField 
                          size="small"
                          value={priceRange.from}
                          sx={{marginRight: '6px'}}
                          onChange={e => setPriceRange({...priceRange, from: e.target.value})}
                        ></TextField>
                        - 
                        <TextField 
                          value={priceRange.to}
                          size="small"
                          sx={{
                            marginLeft: '6px'
                          }}
                          onChange={e => setPriceRange({...priceRange, to: e.target.value})}
                        ></TextField>
                      </Stack>
                      <MyButton onClick={handleApplyPriceRange}>Apply</MyButton>
                    </Stack>
                  </Menu>
                </Stack>
                
              </Stack>
              <Box>                
                <Grid container columnSpacing={2} mb="25px" rowSpacing={{ md: 0, xs: 2 }}>
                  {pagination.keyword && (
                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "#2A3538",
                        }}
                      >
                        Showing Result for {pagination.keyword} Keyword ({saleNfts?.length} NFT’s)
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {/* ---------------------  NFT inner Grid---------------------------- */}
                <Grid container spacing={{ xs: 1.5, md: 5 }} my="10px" justifyContent={"start"}>
                  {saleNfts.map((nft, i) => (
                    <Grid key={i} item md={3} sm={4} xs={6}>
                      <NftItemCard nft={nft} list={handleList} buy={buyHandler} />
                    </Grid>
                  ))}

                  {saleNfts?.length === 0 && (
                    <Typography variant="h2" my={6}>
                      No Nfts Yet !
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Box>

            <Stack direction="row" justifyContent="center" my={5}>
              {hasMore && <LoadMore onClick={handleLoadMore}>Load More</LoadMore>}
            </Stack>
          </Box>
      </Container>
    </Box>
  );
};

export default ShopNft;
