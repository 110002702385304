import { Box, Button, Dialog, IconButton, DialogContent, 
  Grid, Typography, Divider, Stack } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const PaymentSuccessModal = ({ open, id, amount, paymentTime, renewal, cancel }) => {  

const navigate = useNavigate()
return (
  <Dialog        
        open={open}
        onClose={cancel}        
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >        
        <DialogContent>
        <IconButton
          aria-label="close"
          onClick={cancel}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
            <Grid container>
              <Grid item md={7}>       
                <Box p={3}>
                  <Typography fontWeight="bold" fontFamily="'Montserrat', sans-serif" fontSize={36}>Payment Successful</Typography>
                  <Typography fontFamily="'Montserrat', sans-serif" fontSize={20}>Thank you for subscribe!</Typography>
                  
                  <Divider sx={{marginY: '20px', borderColor: '#333333'}}/>
                  
                  <Stack direction="row" fontFamily="'Montserrat', sans-serif" fontSize={16}>
                  <Typography>Amount</Typography>
                  <Typography ml="auto">${amount}</Typography>
                  </Stack>
                  <Stack py={1} direction="row" fontFamily="'Montserrat', sans-serif" fontSize={16}>
                    <Typography>Payment Time</Typography>
                    <Typography ml="auto">{paymentTime}</Typography>
                  </Stack>
                  
                  <Stack py={1}  direction="row" fontFamily="'Montserrat', sans-serif" fontSize={16}>
                    <Typography>Next Payment</Typography>
                    <Typography ml="auto">{renewal}</Typography>
                  </Stack>
                  <Stack py={1} direction="row" fontFamily="'Montserrat', sans-serif" fontSize={16}>
                    <Typography>Ref Number</Typography>
                    <Typography ml="auto">{id}</Typography>
                  </Stack>                

                  <Divider sx={{marginY: '20px', borderColor: '#333333'}}/>
                  
                  <Stack direction="row" alignItems="center">
                    <Button variant="contained" endIcon={<CallMadeIcon/>} onClick={_ => navigate('/myaccount')}>My Dashboard</Button>
                    {/* <Button variant="outlined" endIcon={<DownloadIcon/>} sx={{marginLeft: 2}} onClick={onDownload}>Download Receipt</Button> */}
                  </Stack>
                </Box>         
                
              </Grid>
              <Grid item md={5}>
                <Box ml={2}>
                  <img src={'assets/images/complete.svg'}/>
                </Box>                
              </Grid>
            </Grid>
          
        </DialogContent>        
      </Dialog>
  );
};

export default PaymentSuccessModal;
