import { useContext, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context";
import MyButton from "../Buttonone";
import usePlans from "../../hooks/usePlans";
import { post } from "../../utils/fetchApis";
import ConfirmModal from "../Modal/ConfirmModal";


const UpgradePlan = () => {

  const navigate = useNavigate();
  const { userData, setAlertState } = useContext(AppContext);  
  const [pagination] = useState({page: 1, size: 5, search: ''})
  const { plans } = usePlans({pagination})  
  const [ confirmCancelSubscription, setConfirmCancelSubscription ] = useState(false)
  
  const onCancelSubscription = async() => {
    const {success, message} = await post('subscription/cancel')

    if (!success) {
      setAlertState({
        open: false,
        message,
        serverity: 'error'
      })
    }
  }

  const onSelectPlan = async(id) => {
    const selected = plans.find(p => p.id === id)
    if (selected.name !== 'artist') {      
      navigate(`/checkout?id=${selected.id}`)
      
    } else {
      const {success, message} = await post('subscription/cancel')      
      
      if (!success) {
        setAlertState({
          open: false,
          message,
          severity: 'error'
        })
      }
    }
  }

  const planCard = (plan, index) => {
    const { id, planId, name, description, currency, amount, interval } = plan
    const currentPlan = id === userData.plan.id
    
    let btnLabel = "Upgrade"
    if (currentPlan) {
      if (name === 'artist') {
        btnLabel = "Current Plan"
      } else {
        if (userData.subscription.status === 'ACTIVE') {
          btnLabel = "Cancel Subscription"
        } else {
          btnLabel = "Pending"
        }
      }
    } 

    return <Grid item xs={12} md={4} key={id}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
          borderRadius: "15px",
        }}
      >
        <Box backgroundColor={currentPlan ? "#6FDA44" : "#F6F6F6"} color={currentPlan ? "white" : "black"} p={2}>
          <Typography textTransform={"uppercase"} textAlign={"center"} fontFamily="'Montserrat', sans-serif" fontSize={"22px"} fontWeight={700}>
            {name}
          </Typography>
        </Box>
        <Box backgroundColor={"#2A3539"} color={"white"} pt={2}>
          {description.map((it) => {
            return (
              <Box display={"flex"} alignItems={"center"} mt={2} mx={1} key={it}>
                <CheckCircleIcon sx={{ color: "#6FDA44" }} />
                <Typography fontSize={"16px"} ml={2} fontFamily="'Montserrat', sans-serif" color={"#fff"} fontWeight={"400 !important"}>
                  {it}
                </Typography>
              </Box>
            );
          })}
          <Typography variant="h5" pt={2} textAlign={"center"} color={"#6FDA44"} pb={2} fontFamily="'Montserrat', sans-serif">
            {currentPlan ? "Current Plan" : "Upgrade"}
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <MyButton
          sx={{
            width: "100%",
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: "'Montserrat', sans-serif",
            backgroundColor: currentPlan ? "#F6F6F6" : "#6FDA44",
            color: currentPlan ? "black" : "white",
          }}          
          disabled={currentPlan && (name === 'artist' || userData.subscription.status === 'APPROVAL_PENDING')}
          onClick={_ => currentPlan ? setConfirmCancelSubscription(true) : onSelectPlan(id)}
        >
          {btnLabel}
        </MyButton>
      </Box>
    </Grid>    
  }

  return (
    <>
      <Typography variant="h2" fontFamily="'Montserrat', sans-serif">
        Upgrade Plan
      </Typography>
      <Grid container mt={7} spacing={2}>
        {plans.map((item, index) => (
          planCard(item, index)
        ))}
      </Grid>
      
      <ConfirmModal
        title="Cancel Subscription"
        message="Are you sure to cancel the subscription?"
        open={confirmCancelSubscription}
        cancel={_ => setConfirmCancelSubscription(false)}
        confirm={onCancelSubscription}
       />
    </>
  );
};

export default UpgradePlan;
