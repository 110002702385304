import axios, {isAxiosError} from "axios";

import { PINATA_JWT, secureToken, serverUrl } from "./constant";

const getFormData = (data = null) => {
  const formData = new FormData();
  formData.append("secure_token", secureToken);

  if (data) {
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((val) => formData.append(`${key}[]`, val));
      } else {
        formData.append(key, data[key]);
      }
    });
  }

  return formData;
};

const getQueryParams = (data = null) => {
  const queryData = new URLSearchParams();

  Object.keys(data).forEach(key => {
    queryData.append(key, data[key])
  })

  return queryData;
}

export const getUserDbData = async (id) => {
  const formData = new FormData();
  formData.append("secure_token", secureToken);
  formData.append("user_ID", id);
  const { data } = await axios.post(`${serverUrl}/get-profile-data-by-id.php`, formData);
  return data;
};

export const setAuthHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const post = async (url, params, isForm = false) => {

  try {    
    const { data } = await axios.post(`${serverUrl}/${url}`, !isForm ? params : getFormData(params));  
    if (data.message) {
      return {success: false, message: data.message}
    }
    return {success: true, data}; 

  } catch(e) {
    let message = 'something wrong happened'

    if (isAxiosError(e)) {      
      message = e.response.data.message;
    } else if (e.code) {
      message = e.reason ?? e.message;
    }
    return {success: false, message}; 
  }  
};

export const get = async (url, params) => {
  try {
    const { data } = await axios.get(`${serverUrl}/${url}?${params ? getQueryParams(params) : ''}`);
    
    if (data.message) {
      return {success: false, message: data.message}
    }
    return {success: true, data}; 

  } catch(e) {
    let message = 'something wrong happened'

    if (isAxiosError(e)) {      
      message = e.response.data.message;
    } else if (e.code) {
      message = e.reason ?? e.message;
    }
    return {success: false, message}; 
  }  
};

export const update = async(url, params, isForm = false) => {
  try {
    const { data } = await axios.put(`${serverUrl}/${url}`, !isForm ? params : getFormData(params));
    if (data.message) {
      return {success: false, message: data.message}
    }
    return {success: true, data}; 

  } catch(e) {
    let message = 'something wrong happened'

    if (isAxiosError(e)) {
      message = e.response.data.message;
    } else if (e.code) {
      message = e.reason ?? e.message;
    }
    return {success: false, message}; 
  }  
}

export const remove = async(url, params) => {
  try {
    const { data } = await axios.delete(`${serverUrl}/${url}`, params)
    if (data.message) {
      return {success: false, message: data.message}
    }
    return {success: true, data}; 

  } catch(e) {
    let message = 'something wrong happened'

    if (isAxiosError(e)) {
      message = e.response.data.message;
    } else if (e.code) {
      message = e.reason ?? e.message;
    }
    return {success: false, message}; 
  }  
}

export const pinFileToIPFS = async (params) => {
  const formData = new FormData();
  formData.append("file", params.file);

  const pinataMetadata = JSON.stringify({
    name: params.name,
  });
  formData.append("pinataMetadata", pinataMetadata);

  const { data } = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
    maxBodyLength: "Infinity",
    headers: {
      "Content-Type": "multipart/form-data;",
      Authorization: PINATA_JWT,
    },
  });

  return data;
};

export const pinJSONToIPFS = async (params) => {
  const data = JSON.stringify({
    pinataContent: {
      name: params.name,
      description: params.description,
      external_url: "",
      image: params.image,
    },
    pinataMetadata: {
      name: "metadata.json",
    },
  });

  const res = await axios.post("https://api.pinata.cloud/pinning/pinJSONToIPFS", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: PINATA_JWT,
    },
  });

  return res.data;
};
