import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, FormControl, Grid, IconButton, Input, InputAdornment, MenuItem, OutlinedInput, Select, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAccount, useSigner } from "wagmi";
import { AppContext } from "../../Context";
import Loading from "../../connectivityAssets/Loading";
import { mintContractAddr } from "../../connectivityAssets/mintContract/addresses";
import { DEV_MODE, NFT_STATUS, PINATA_GATEWAY } from "../../utils/constant";
import { getTokenId, mintNFT } from "../../utils/contract";
import { pinFileToIPFS, pinJSONToIPFS, get, post } from "../../utils/fetchApis";
import { repeatElements } from "../../utils/helper";
import ConnectionModal from "../Modal/ConnectModal";
import { GreenButton } from "../Styled";
import { inputStyle } from "../Styled/style";
import { getEthersSigner } from "../../utils/ether";
import { config } from "../../utils/wagiConfig";
import { isAxiosError } from "axios";
import { USER_ROLE } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tag from "../Tag";

const UploadContent = () => {

  const navigate = useNavigate();
  const [filetype, setFiletype] = useState("Book");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState("");
  const [mintFiles, setMintFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [bookValue, setBookValue] = useState("");
  const [books, setBooks] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);

  const [description, setDescription] = useState("");
  const [secondScreen, setSecondScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkImg, setCheckImg] = useState(true);
  const [checkMintImg, setCheckMintImg] = useState(true);
  const [checkPreviewImg, setCheckPreviewImg] = useState(true);
  const [nftLimit, setNftLimit] = useState(false)
  //connect
  const { wallet, userData, setUserData, setTabIndex, toggleConnectModal, setAlertState } = useContext(AppContext);
  const { isConnected, address } = useAccount();  
  
  // Book submit handler

  const bookSubmit = () => {
    if (bookValue.length < 1) {
      setAlertState({
        open: true,
        message: "Type something in editor",
        severity: "error",
      });
    } else {
      setBooks([...books, bookValue]);
      setBookValue("");
    }
  };

  const handleUpgrade = () => {
    setTabIndex(2)
  }

  //file setup
  const onfirstDrop = useCallback((acceptedFiles) => {
    setImage(...acceptedFiles);
  }, []);

  const onSecondDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        let _files = [];
        let _fileUrl = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
          const date = new Date();
          const name = `${date.getMilliseconds()}${date.getSeconds()}${date.getMinutes()}${Math.random()}`.replace(".", "0");
          let newFile = new File([acceptedFiles[i]], name);
          const url = URL.createObjectURL(acceptedFiles[i]);

          Object.defineProperty(newFile, "size", {
            value: acceptedFiles[i]?.size,
            writable: false,
          });

          Object.defineProperty(newFile, "displayName", {
            value: acceptedFiles[i]?.path,
            writable: false,
          });

          _files.push(newFile);
          _fileUrl.push(url);
        }
        setMintFiles([...mintFiles, ..._files]);
        setFileUrl([...fileUrl, ..._fileUrl]);
      }
    },
    [mintFiles]
  );

  const onPreviewDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        let _files = [];
        for (let i = 0; i < acceptedFiles.length; i++) {
          const date = new Date();
          const name = `${date.getMilliseconds()}${date.getSeconds()}${date.getMinutes()}${Math.random()}`.replace(".", "0");
          let newFile = new File([acceptedFiles[i]], name);

          Object.defineProperty(newFile, "size", {
            value: acceptedFiles[i]?.size,
            writable: false,
          });

          Object.defineProperty(newFile, "displayName", {
            value: acceptedFiles[i]?.path,
            writable: false,
          });

          _files.push(newFile);
        }
        setPreviewFiles([...previewFiles, ..._files]);
      }
    },
    [previewFiles]
  );

  const firstDropZone = useDropzone({
    onDrop: onfirstDrop,
    accept: {
      "image/*": [],
    },
  });

  const secondDropZone = useDropzone({
    onDrop: onSecondDrop,
    multiple: true,
    accept: filetype === "Book" ? { "application/pdf": [] } : filetype === "Video" ? { "video/*": [] } : { "audio/*": [] },
  });

  const previewDropZone = useDropzone({
    onDrop: onPreviewDrop,
    multiple: false,
    accept: filetype === "Video" ? { "video/*": [] } : { "audio/*": [] },
  });

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const handleMediaChange = (event) => {
    setFiletype(event.target.value);
    setMintFiles([]);
    setPreviewFiles([]);
    setBooks([]);
  };

  useEffect(() => {
    if (mintFiles.length > 0 || books.length > 0) {
      setCheckMintImg(true);
    }
  }, [mintFiles, books]);

  useEffect(() => {
    if (previewFiles.length > 0) {
      setCheckPreviewImg(true);
    }
  }, [previewFiles]);

  useEffect(() => {
    if (userData.role === USER_ROLE.USER && (userData?.plan.name === "artist" && userData.totalNFT >= 10 ||
      userData?.plan.name === "record label" && userData.totalNFT >= 100 && userData?.subscription.status === 'ACTIVE' ||
      userData?.plan.name === "major label" && userData.totalNFT >= 1000 && userData?.subscription.status === 'ACTIVE')  ) {      
      
      setNftLimit(true)
    }
  }, [userData])

  const resetForm = () => {
    setTitle("");
    setPrice("");
    setFiletype("");
    setDescription("");
    setImage();
    setMintFiles([]);
    setBooks([]);
  };

  const handleNext = useCallback(async () => {
    const valid = await trigger(["title", "price", "quantity"]);

    if (!image) {
      setCheckImg(false);
    } else {
      setCheckImg(true);
    }
    if (valid && image) {
      setSecondScreen(true);
    }
  }, [image, trigger]);

  const handleMintNft = useCallback(async () => {
    try {                  
      
      const valid = await trigger(["desc", "fileType"]);
      if (!mintFiles) {
        setCheckMintImg(false);
      } else {
        setCheckMintImg(true);
      }

      if (filetype === "Book" && !books.length) {
        setCheckMintImg(false);
        setAlertState({
          open: true,
          message: "Submit books before minting",
          severity: "error",
        });
        return;
      }

      if (!mintFiles.length) {
        setCheckMintImg(false);
        setAlertState({
          open: true,
          message: "Upload files before minting",
          severity: "error",
        });
        return;
      }

      if (!previewFiles.length) {
        setCheckPreviewImg(false);
        setAlertState({
          open: true,
          message: "Upload preview files before minting",
          severity: "error",
        });
        return;
      }

      if (valid && mintFiles) {
        setLoading(true);        
        
        
      const fileIpfs = await pinFileToIPFS({
        file: image,
        name: image.name,
      });

      const metaIpfs = await pinJSONToIPFS({
        name: title,
        description,
        creator: address,
        royality: 0.25,
        image: `https://gateway.pinata.cloud/ipfs/${fileIpfs.IpfsHash}`,
      });

      const tokenId = await getTokenId();
      const signer = await getEthersSigner(config);
      await mintNFT(signer, repeatElements(`${PINATA_GATEWAY}${metaIpfs.IpfsHash}`, parseInt(quantity)));
      const params = {              
        title: title,
        image: image,
        price: price,
        fileType: filetype,
        description: description,
        nftIds: Array(Number(quantity))
          .fill(0)
          .map((it, idx) => parseInt(tokenId) + parseInt(idx) + 1),
          contractAddress: mintContractAddr,
        walletId: wallet.id,
        tags: tags,
        preview: previewFiles[0],
      };

      if (filetype === "Book") {
        params.book_description = books;
      } else {
        params.file = mintFiles[0];
      }            
      
      const {success, data, message} = await post(
        'nfts/create',
        params,
        true,
      );

      if (!success) {
        setAlertState({
          open: true,
          message,
          severity: "error",
        })
      }

      if (data) {
        setLoading(false);
        setUserData({...userData, totalNFT: userData.totalNFT + quantity})
        setAlertState({
          open: true,
          message: "Nft Minted Successfully",
          severity: "success",
        });

        resetForm()                            
      }
                
      }
    } catch (e) {
      setLoading(false);
      
      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
    }
  }, [
    address,
    books,
    description,
    filetype,
    image,
    mintFiles,
    previewFiles,
    price,
    quantity,    
    tags,
    title,
    trigger,
    userData?.payment_date,
    userData?.plan_label,
    userData?.user_ID,
  ]);

  const handleRemoveTag = (name) => {
    setTags(tags.filter(t => t !== name))
  }
  // console.log('user', userData.role, userData.plan, userData.totalNFT)

  return (
    <Box position="relative">
      <ConnectionModal />      
      {loading && <Loading isLoading={loading} />}

      <Typography variant="h2" fontFamily="'Montserrat', sans-serif">
        Upload content
      </Typography>

      {nftLimit ? <Box display="flex" flexDirection="column" alignItems="center" p={10}>        
        <Typography variant="body1" mt="3">
          You can't mint nft any more. Please upgrade your membership to mint more nfts.          
        </Typography>
        <GreenButton onClick={handleUpgrade} sx={{ width: "100px", marginTop: "20px", fontFamily: "'Montserrat', sans-serif" }}>
                Upgrade
        </GreenButton>
        
      </Box> : 
      <>
        {!secondScreen && (
          <Box mt="20px">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif">
                  Title
                </Typography>
                <TextField
                  {...register("title", { required: true })}
                  sx={inputStyle}
                  placeholder="Enter the title here"
                  error={errors["title"] && true}
                  value={title}
                  size="small"
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                  helperText={errors["title"]?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif">
                  <CloudUploadIcon
                    sx={{
                      color: "black",
                      px: "5px",
                      fontSize: "25px",
                      pt: "10px",
                    }}
                  />
                  UPLOAD IMAGE
                </Typography>
                <Box className="container">
                  <Box
                    {...firstDropZone?.getRootProps({ className: "dropzone" })}
                    sx={{
                      py: "20px",
                      borderRadius: "10px",
                      border: !checkImg ? "1px solid red" : "2px solid #BDBDBD",
                      cursor: "pointer",
                      backgroundColor: "#F6F6F6",
                      px: "10px",
                      transition: "0.5s linear",
                      "&:hover": {
                        border: "2px solid #6FDA44",
                      },
                    }}
                  >
                    <input {...firstDropZone?.getInputProps()} />
                    <Typography textAlign={"center"} fontFamily="'Montserrat', sans-serif">
                      {" Drag 'n' drop some files here, or click to select files"}
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "12px", color: "red" }}>{!checkImg && "*This Field Is Required"}</Typography>

                  {image && (
                    <aside>
                      <img height={200} width={200} style={{ marginTop: "10px" }} src={URL.createObjectURL(image)} alt="" />
                      <Typography mt="10px" variant="h4" fontFamily="'Montserrat', sans-serif">
                        Files
                      </Typography>
                      <ul>
                        <li>
                          {image?.path} - {image?.size} bytes
                        </li>
                      </ul>
                    </aside>
                  )}
                </Box>
              </Grid>

              {/* <Grid item xs={12} md={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif">
                  PRICE
                </Typography>
                <TextField
                  {...register("price", { required })}
                  sx={inputStyle}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      // console.log('price', e.target.value)
                      setPrice(e.target.value);
                    }
                  }}
                  value={price}
                  error={errors["price"] && true}
                  placeholder="10 USDT"
                  size="small"
                  fullWidth
                  helperText={errors["price"]?.message}
                />
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif">
                  QUANTITY
                </Typography>
                <TextField
                  {...register("quantity", { required: true })}
                  sx={inputStyle}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setQuantity(e.target.value);
                    }
                  }}
                  value={quantity}
                  error={errors["quantity"] && true}
                  size="small"
                  fullWidth
                  helperText={errors["quantity"]?.message}
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif">
                    Tag
                  </Typography>
                <OutlinedInput
                    {...register("tag", { required: true })}
                    sx={inputStyle}
                    onChange={(e) => {
                      console.log('tag', e.target.value)
                      if (e.target.value) {
                        setTag(e.target.value);
                      }
                    }}
                    value={tag}
                    error={errors["tag"] && true}
                    size="small"
                    fullWidth                    
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={_ => {setTags([...tags, tag]);setTag('')}}>
                          <AddCircleIcon/>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Stack alignItems="center" direction='row' mt={1}>
                    {tags.map(tag => <Tag name={tag} closable onClose={handleRemoveTag}></Tag>)}
                  </Stack>
              </Grid>
            </Grid>
            <Box mt="20px">
              <GreenButton onClick={handleNext} sx={{ width: "100px", fontFamily: "'Montserrat', sans-serif" }}>
                Next
              </GreenButton>
            </Box>
          </Box>
        )}

        {secondScreen && (
          <Box mt="20px">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h4">FILE TYPE</Typography>
                <FormControl size="small" fullWidth>
                  <Select
                    {...register("fileType", { required: true })}
                    error={errors["fileType"] && true}
                    // helperText={errors["fileType"]?.message}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={filetype}
                    onChange={handleMediaChange}
                    color="primary"
                    small="true"
                    fullWidth
                    sx={{ backgroundColor: "#F6F6F6" }}
                  >
                    <MenuItem value={"Audio"}>Audio</MenuItem>
                    <MenuItem value={"Video"}>Video</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* {!!fileUrl.length && (
                <Grid item container spacing={2}>
                  {fileUrl.map((item, index) =>
                    filetype === "Audio" ? (
                      <Grid item key={index} xs={12} lg={4}>
                        <audio
                          src={item}
                          controls
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Grid>
                    ) : (
                      <Grid item key={index} xs={12} lg={4}>
                        <video
                          src={item}
                          controls
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              )} */}

              <Grid item xs={12} gap={12}>
                {filetype === "Book" ? (
                  <>
                    <Typography variant="h4">TYPE HERE</Typography>

                    <ReactQuill theme="snow" value={bookValue} onChange={setBookValue} style={{ border: !checkMintImg ? "1px solid red" : "none" }} />
                    {!checkMintImg && (
                      <Typography sx={{ fontSize: "12px", color: "red" }}>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        "*This Field Is Required"
                      </Typography>
                    )}
                    <Box py="20px" display="flex" justifyContent="end">
                      <GreenButton onClick={bookSubmit}> Submit </GreenButton>
                    </Box>

                    <Grid container spacing={3}>
                      {books?.map((file, i) => {
                        return (
                          <Grid item key={i} md={6} xs={12}>
                            <Box
                              height={300}
                              overflow="scroll"
                              sx={{
                                "&::-webkit-scrollbar": {
                                  width: "0 !important",
                                },
                                "&::-webkit-scrollbar:horizontal": {
                                  height: "4px !important",
                                },
                                "&::-webkit-scrollbar:verticle": {
                                  width: "10px !important",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  background: "#6FDA44",
                                },
                                boxShadow: 4,
                                p: 3,
                                my: 2,
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: file,
                                }}
                              />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography variant="h4">
                      <CloudUploadIcon
                        sx={{
                          color: "black",
                          px: "5px",
                          fontSize: "25px",
                          pt: "10px",
                        }}
                      />
                      PREVIEW FILE
                    </Typography>
                    <Box className="container">
                      <Box
                        {...previewDropZone?.getRootProps({
                          className: "dropzone",
                        })}
                        sx={{
                          py: "20px",
                          borderRadius: "10px",
                          border: !checkPreviewImg ? "1px solid red" : "2px solid #BDBDBD",
                          cursor: "pointer",
                          backgroundColor: "#F6F6F6",
                          px: "10px",
                          transition: "0.5s linear",
                          "&:hover": {
                            border: "2px solid #6FDA44",
                          },
                        }}
                      >
                        <input {...previewDropZone?.getInputProps()} />
                        <Typography textAlign={"center"}>{"Drag 'n' drop some files here, or click to select files"}</Typography>
                      </Box>
                      <Typography sx={{ fontSize: "12px", color: "red" }}>{!checkPreviewImg && "*This Field Is Required"}</Typography>
                      {previewFiles && (
                        <aside>
                          <Typography mt="10px" variant="h4">
                            Files
                          </Typography>
                          {previewFiles?.map((file, i) => (
                            <ul key={i}>
                              <li style={{ display: "flex", alignItems: "center" }}>
                                {file?.displayName?.slice(0, 25)} - {Number(file?.size) / 1000} KB
                                <IconButton
                                  sx={{ marginLeft: "10px" }}
                                  size="small"
                                  onClick={(_) => {
                                    setPreviewFiles(previewFiles.filter((_, idx) => i != idx));
                                  }}
                                >
                                  <ClearIcon
                                    sx={{
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              </li>
                            </ul>
                          ))}
                        </aside>
                      )}
                    </Box>
                    <Typography variant="h4">
                      <CloudUploadIcon
                        sx={{
                          color: "black",
                          px: "5px",
                          fontSize: "25px",
                          pt: "10px",
                        }}
                      />
                      UPLOAD FILE
                    </Typography>
                    <Box className="container">
                      <Box
                        {...secondDropZone?.getRootProps({
                          className: "dropzone",
                        })}
                        sx={{
                          py: "20px",
                          borderRadius: "10px",
                          border: !checkMintImg ? "1px solid red" : "2px solid #BDBDBD",
                          cursor: "pointer",
                          backgroundColor: "#F6F6F6",
                          px: "10px",
                          transition: "0.5s linear",
                          "&:hover": {
                            border: "2px solid #6FDA44",
                          },
                        }}
                      >
                        <input {...secondDropZone?.getInputProps()} />
                        <Typography textAlign={"center"}>{"Drag 'n' drop some files here, or click to select files"}</Typography>
                      </Box>
                      <Typography sx={{ fontSize: "12px", color: "red" }}>{!checkMintImg && "*This Field Is Required"}</Typography>
                      {mintFiles && (
                        <aside>
                          <Typography mt="10px" variant="h4">
                            Files
                          </Typography>
                          {mintFiles?.map((file, i) => (
                            <ul key={i}>
                              <li>
                                {file?.displayName?.slice(0, 25)} - {Number(file?.size) / 1000} KB
                                <IconButton
                                  sx={{ marginLeft: "10px" }}
                                  size="small"
                                  onClick={(_) => {
                                    setMintFiles(mintFiles.filter((_, idx) => i != idx));
                                  }}
                                >
                                  <ClearIcon
                                    sx={{
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              </li>
                            </ul>
                          ))}
                        </aside>
                      )}
                    </Box>
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">SHORT DESCRIPTION</Typography>

                <Box
                  sx={{
                    border: errors["desc"] ? "1px solid red" : "2px solid #BDBDBD",
                    transition: "0.5s linear",
                    "&:hover": {
                      border: "2px solid #6FDA44",
                    },
                    borderRadius: "7px",
                  }}
                >
                  <Box py={"5px"} sx={{ background: "#F6F6F6", borderRadius: "7px" }}>
                    <Typography fontSize={"18px"} fontWeight="500" textAlign={"center"}>
                      FORMATTING TOOLS INSERTED HERE
                    </Typography>
                  </Box>

                  <TextareaAutosize
                    style={{
                      width: "100%",
                      height: "80px",
                      border: "none",
                      overflow: "auto",
                      outline: "none",
                      WebkitBoxShadow: "none",
                      MozBoxShadow: "none",
                      boxShadow: "none",
                      resize: "none",
                    }}
                    {...register("desc", { required: true })}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    placeholder="Write the short description that will appear next to the presentation image"
                    size="small"
                  ></TextareaAutosize>
                </Box>
                <Typography sx={{ fontSize: 12, color: "red" }}>{errors["desc"]?.message}</Typography>
              </Grid>
            </Grid>
            <Box mt="20px">
              <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
                <GreenButton onClick={() => setSecondScreen(false)} sx={{ width: "100px" }}>
                  Back
                </GreenButton>

                {!isConnected && (
                  <GreenButton onClick={_ => toggleConnectModal(true)} sx={{ width: "100px" }}>
                    Connect Wallet
                  </GreenButton>
                )}
                {isConnected && (
                  <GreenButton onClick={handleMintNft} sx={{ width: "100px" }}>
                    Mint NFT
                  </GreenButton>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </>
      }      
    </Box>
  );
};

export default UploadContent;
