import { useCallback, useContext, useState,  } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Divider, Input, Menu, MenuItem, ListItemIcon, ListItemText, Stack, SvgIcon, TableFooter, TablePagination } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import useFaqList from "src/hooks/useFaq";
import ConfirmModal from "../Modal/ConfirmModal";
import FaqEditModal from "../Modal/FaqEditModal";
import { post, remove, update } from "src/utils/fetchApis";
import { AppContext } from "src/Context";

const FaqManagement = () => {
    const {setAlertState} = useContext(AppContext)
    const [pagination, setPagination] = useState({
        page: 1,
        size: 5,
        search: ''
    })
    const {faqList, count, refetch} = useFaqList(pagination)    
    const [anchorEl, setAnchorEl] = useState(null);
    const [removeFaqConfirm, setRemoveFaqConfirm] = useState(false)
    const [editFaq, setEditFaq] = useState('')
    const [selectedFaq, setSelectedFaq] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
      }, []);
    
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleRemove = (plan) => {
      setAnchorEl(null);
      setSelectedFaq(plan)
      setRemoveFaqConfirm(true)
    };        

    const handleAdd = () => {
      setAnchorEl(null);      
      setEditFaq('add')
    };    

    const handleUpdate = (faq) => {
      setAnchorEl(null);
      setSelectedFaq(faq)
      setEditFaq('edit')
    };    

    const handleChangePage = (event, newPage) => {      
      setPagination({...pagination, page: newPage + 1});
    };
    
    const handleChangeRowsPerPage = (event) => {        
        setPagination({...pagination, size: event.target.value, page: 1})
    };

    const handleSubmitFaq = async(plan) => {      

      if (editFaq === 'add') {
        const {success, message} = await post('faqs', {...plan, order: faqList.length})

        if (!success) {
          setAlertState({
            open: true,
            message,
            serverity: 'error'
          })
          setEditFaq(false)
          return
        }
        setAlertState({
          open: true,
          message: 'Success to save new plan',
          serverity: 'success'
        })
      } else if (editFaq === 'edit') {
        const {success, message} = await update(`faqs/${selectedFaq.id}`, plan)

        if (!success) {
          setAlertState({
            open: true,
            message,
            serverity: 'error'
          })
          setEditFaq(false)
          return
        }

        setAlertState({
          open: true,
          message: 'Success to edit plan',
          serverity: 'success'
        })
      }

      refetch(pagination)
      setSelectedFaq(null)
      setEditFaq(false)
  }

    const handleRemovePlan = async() => {

      const {success, message} = await remove(`faqs/${selectedFaq.id}`)

      if (!success) {
        setAlertState({
          open: true,
          message,
          serverity: 'error'
        })
        return
      }

      setRemoveFaqConfirm(false)
      setSelectedFaq(null)
      setAlertState({
        open: true,
        message: 'Success to remove plan',
        serverity: 'success'
      })
      refetch(pagination)
    }
    console.log(faqList)

    return (
        <>
            <Stack direction='row' className="my-2">
              <Input
                placeholder="Search Faq"
                value={pagination.search}
                onChange={e => setPagination({...pagination, search: e.target.value})}
              />

              <Button sx={{marginLeft: 'auto'}} onClick={handleAdd}>Add Faq</Button>
            </Stack>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>            
                    <TableCell >Title</TableCell>
                    <TableCell >Description</TableCell>
                    <TableCell >Status</TableCell>                    
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {faqList.map((faq) => (
                    <TableRow
                    key={faq.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {faq.title}
                    </TableCell>                                        
                    <TableCell >
                      <div dangerouslySetInnerHTML={{__html: faq.description}}></div>
                      </TableCell>
                    <TableCell >{faq.status ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell >
                        <Button onClick={handleClick}>
                            <SvgIcon>
                                {/* { THREE_DOT_VERTICAL } */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                            </SvgIcon>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >            
                          <MenuItem onClick={_ => handleUpdate(faq)}>
                            <ListItemIcon>
                              <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                              Edit
                            </ListItemText>
                          </MenuItem>  
                          <Divider component="li" />              
                          <MenuItem onClick={() => handleRemove(faq)}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Remove
                                </ListItemText>
                          </MenuItem>
                        </Menu>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
                <TableFooter>
                    <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={count}
                    rowsPerPage={pagination.size}
                    page={pagination.page - 1}
                    slotProps={{
                        select: {
                        inputProps: {
                            "aria-label": "rows per page",
                        },
                        native: true,
                        },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ></TablePagination>
                    </TableFooter>
            </Table>
        </TableContainer>
        <ConfirmModal
            open={removeFaqConfirm}
            title="Remove Faq"
            message="Are you sure to remove Faq?"
            confirm={handleRemovePlan}
            cancel={() => setRemoveFaqConfirm(false)}
        />
        {!!editFaq && <FaqEditModal
          open={!!editFaq}
          action={editFaq}
          faq={selectedFaq}
          save={handleSubmitFaq}
          cancel={_ => {
            setEditFaq('')
            setSelectedFaq(null)
          }}
        />} 
        </>        
      );
}

export default FaqManagement