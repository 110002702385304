import { useCallback, useContext, useState,  } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Divider, Input, Menu, MenuItem, ListItemIcon, ListItemText, Stack, SvgIcon, TableFooter, TablePagination } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import usePlans from "src/hooks/usePlans";
import ConfirmModal from "../Modal/ConfirmModal";
import PlanEditModal from "../Modal/PlanEditModal";
import { post, remove, update } from "src/utils/fetchApis";
import { AppContext } from "src/Context";

const PlanManagement = () => {
    const {setAlertState} = useContext(AppContext)
    const [pagination, setPagination] = useState({
        page: 0,
        size: 5,
        search: ''
    })
    const {plans, count, refetch} = usePlans({pagination})    
    const [anchorEl, setAnchorEl] = useState(null);
    const [removePlanConfirm, setRemovePlanConfirm] = useState(false)
    const [editPlan, setEditPlan] = useState('')
    const [selectedPlan, setSelectedPlan] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
      }, []);
    
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleRemove = (plan) => {
      setAnchorEl(null);
      setSelectedPlan(plan)
      setRemovePlanConfirm(true)
    };        

    const handleAdd = (plan) => {
      setAnchorEl(null);      
      setEditPlan('add')
    };    

    const handleUpdate = (plan) => {
      setAnchorEl(null);
      setSelectedPlan(plan)
      setEditPlan('edit')
    };    

    const handleChangePage = (event, newPage) => {
        setPagination({...pagination, page: newPage});
      };
    
    const handleChangeRowsPerPage = (event) => {        
        setPagination({...pagination, size: event.target.value, page: 1})
    };

    const handleSubmitPlan = async(plan) => {      

      if (editPlan === 'add') {
        const {success, message} = await post('subscription/plan', plan)

        if (!success) {
          setAlertState({
            open: true,
            message,
            serverity: 'error'
          })
          setEditPlan(false)
          return
        }
        setAlertState({
          open: true,
          message: 'Success to save new plan',
          serverity: 'success'
        })
      } else if (editPlan === 'edit') {
        const {success, message} = await update(`subscription/plan/${selectedPlan.id}`, plan)

        if (!success) {
          setAlertState({
            open: true,
            message,
            serverity: 'error'
          })
          setEditPlan(false)
          return
        }

        setAlertState({
          open: true,
          message: 'Success to edit plan',
          serverity: 'success'
        })
      }

      refetch()
      setSelectedPlan(null)
      setEditPlan(false)
  }

    const handleRemovePlan = async() => {

      const {success, message} = await remove(`subscription/plan/${selectedPlan.id}`)

      if (!success) {
        setAlertState({
          open: true,
          message,
          serverity: 'error'
        })
        return
      }

      setRemovePlanConfirm(false)
      setSelectedPlan(null)
      setAlertState({
        open: true,
        message: 'Success to remove plan',
        serverity: 'success'
      })
      refetch()
    }
    // console.log(plans)

    return (
        <>
            <Stack direction='row' className="my-2">
              <Input
                placeholder="Search Plan"
                value={pagination.search}
                onChange={e => setPagination({...pagination, search: e.target.value})}
              />

              <Button sx={{marginLeft: 'auto'}} onClick={handleAdd}>Add plan</Button>
            </Stack>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>            
                    <TableCell >Name</TableCell>
                    <TableCell >Price</TableCell>
                    <TableCell >NFT Limit</TableCell>
                    <TableCell >Interval</TableCell>
                    <TableCell >Description</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {plans.map((plan) => (
                    <TableRow
                    key={plan.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {plan.name}
                    </TableCell>                    
                    <TableCell >{plan.price}</TableCell>              
                    <TableCell >{plan.amount}</TableCell>
                    <TableCell >{plan.interval}</TableCell>
                    <TableCell >{plan.description}</TableCell>
                    <TableCell >
                        <Button onClick={handleClick}>
                            <SvgIcon>
                                {/* { THREE_DOT_VERTICAL } */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                            </SvgIcon>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >            
                          <MenuItem onClick={_ => handleUpdate(plan)}>
                            <ListItemIcon>
                              <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                              Edit
                            </ListItemText>
                          </MenuItem>  
                          <Divider component="li" />              
                          <MenuItem onClick={() => handleRemove(plan)}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Remove
                                </ListItemText>
                          </MenuItem>
                        </Menu>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
                <TableFooter>
                    <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={count}
                    rowsPerPage={pagination.size}
                    page={pagination.page}
                    slotProps={{
                        select: {
                        inputProps: {
                            "aria-label": "rows per page",
                        },
                        native: true,
                        },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ></TablePagination>
                    </TableFooter>
            </Table>
        </TableContainer>
        <ConfirmModal
            open={removePlanConfirm}
            title="Remove plan"
            message="Are you sure to remove plan?"
            confirm={handleRemovePlan}
            cancel={() => setRemovePlanConfirm(false)}
        />
        {!!editPlan && <PlanEditModal
          open={!!editPlan}
          action={editPlan}
          plan={selectedPlan}
          save={handleSubmitPlan}
          cancel={_ => {
            setEditPlan('')
            setSelectedPlan(null)
          }}
        />} 
        </>        
      );
}

export default PlanManagement