import { Avatar, Box, Container, Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AddToCart from "../assets/add-to-cart.png";
import Currency from "../assets/currency.png";
import Logo from "../assets/logo-media.png";
import Play from "../assets/play-button.png";
import PlayBlack from "../assets/play-button_black.png";
import Recording from "../assets/recording.jpeg";
import ShareIcon from "../assets/share.png";
import Video from "../components/Video";
import Loading from "../connectivityAssets/Loading";
import useDocumentTitle from "../useDocumentTitle";
import { useNfts, useNft } from "../hooks";
import { GC_BUCKET_URL } from "../utils/constant";

import "./style.scss";


const VideoLibrary = () => {

  const params = useParams();  
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState("");
  const {nfts} = useNfts()  
  const [showMainPicture, setShowMainPicture] = useState(true);
  const logoRef = useRef(null);
  const {nft} = useNft(params?.id);

  useDocumentTitle("Enjoy this exclusive content | Sell linked NFT at a profit.");

  const handleShare = async () => {
    const shareData = {
      title: "Web Share API",
      url: window.location.href,
    };
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        // console.log("🥳 Shared via API.");
      } catch (error) {
        // console.log(`😢 ${error}`);
      }
    } else {
      // console.log("😢 Your browser does not support the web share api.");
    }
  };

  const handleOnPlayMedia = () => {
    logoRef.current.classList.add("spin");
  };

  const handleOnPauseMedia = () => {
    logoRef.current.classList.remove("spin");
  };

  const onSelectImage = useCallback((source) => {
    setSource(source);
    setShowMainPicture(false);
  }, []);

  const renderType = useCallback((type) => {
    switch (type) {
      case "Video":
        return "VIDEOS";
      case "audio":
        return "AUDIOS";
      default:
        return "";
    }
  }, []);

  return (
    <Container maxWidth="lg">
      {loading && <Loading isLoading={loading} />}
      <Grid container my={3}>
          <div className="media-content">
            <div className="main-content">
              <div style={{ position: "relative" }}>
                <Video
                  url={source}
                  image={''}
                  className={`media-player ${nft?.fileType === "audio" && "audio"}`}
                  handleOnPlayMedia={handleOnPlayMedia}
                  handleOnPauseMedia={handleOnPauseMedia}
                />
                {nft?.fileType === "audio" && <img src={Logo} alt="" className="logo-media" ref={logoRef} />}
                {nft?.fileType === "Video" && !!showMainPicture && <img src={Recording} alt="" ref={logoRef} className="video-background" />}
              </div>

              <h2 className="desc-media">{nft?.title}</h2>
              <div className="d-flex justify-content-between mb-4">
                <div className="d-flex align-items-center" style={{ gap: "20px" }}>
                  <img className="avatar" alt="" src={GC_BUCKET_URL + nft?.image || Avatar} />
                  <div className="user-info">
                    <div className="name">{nft?.user_name}</div>
                    <p className="sub-info">
                      {nft?.total_files} {renderType(nft?.fileType)}
                    </p>
                  </div>
                </div>
                <button className="btn-share" onClick={handleShare}>
                  <img src={ShareIcon} alt="" />
                  Share
                </button>
              </div>
              <div className="media-description">
                <div className="media-info">
                  <p>3 days ago</p>
                  <p>Category: Film</p>
                </div>
                <p className="media-desc">{nft?.short_description}</p>
              </div>
              <div className="hide-on-mobile">
                <h2 className="sub-title">Other NFTs from {nft?.user_name}</h2>
                <Grid container spacing={3}>
                  {nfts?.slice(0, 4).map((item) => (
                    <Grid item xs={6} md={3} key={item?.content_ID}>
                      <div className="card-items">
                        <img src={`${GC_BUCKET_URL}${item?.image}`} alt="" width={217} />
                        <p style={{ marginLeft: "10px" }}>{item?.title}</p>
                        <div className="currency">
                          <img src={Currency} alt="" />
                          <p>{nft?.price} USDT</p>
                        </div>
                        <div className="buy">
                          <button>BUY NOW</button>
                          <div>
                            <img src={AddToCart} alt="" />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
            <div className="list">
              {/* {nft?.files?.map((item, index) => ( */}
                <div className="items">
                  {nft?.fileType === "audio" ? (
                    <div
                      className="image-cover audio-image"
                      onClick={() => {
                        onSelectImage(GC_BUCKET_URL + nft?.file);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img src={PlayBlack} alt="" className="icon-play" />
                    </div>
                  ) : (
                    <div
                      className="image-cover"
                      onClick={() => {
                        onSelectImage(GC_BUCKET_URL + nft?.file);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img src={Recording} alt="" width={149} height={84} />
                      <img src={Play} alt="" className="icon-play" />
                    </div>
                  )}

                  <div style={{ flex: "1 0 48%" }}>
                    <p className="media-title">{nft?.title}</p>
                    <p className="media-desc">{nft?.short_description}</p>
                  </div>
                </div>
              {/* ))} */}
            </div>
          </div>
      </Grid>  
    </Container>
  );
};

export default VideoLibrary;
