import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import MyButton from "../Buttonone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Height } from "@mui/icons-material";

const FaqEditModal = ({ action, faq, open, save, cancel }) => {      
  const {
    register,
    handleSubmit,    
    getValues,
    setValue,
    formState: { errors, isDirty, dirtyFields },    
  } = useForm({
    defaultValues: {
      title: faq && faq.title ? faq.title : '',      
      description: faq && faq.description ? faq.description : '',
      status: faq && faq.status ? faq.status : true,
    }
  });  

  const handleSubmitPlan = (formValue) => {        
    if (action === 'add') {
      
      formValue['description'] = formValue['description'].split(',')
      save(formValue)
    } else {
      const dirtyValues = {}
      Object.keys(dirtyFields).map(key => {
        if (key === 'description') 
          dirtyValues[key] = formValue[key].split(',')
        else
          dirtyValues[key] = formValue[key]
      })
      
      save(dirtyValues)
    } 
  }
  
  console.log(faq)
  
  return (
    <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" alignItems="center">
            {action} faq
            <IconButton 
              onClick={cancel} 
              sx={{ marginLeft: "auto" }} 
              color="primary"               
              component="label">
              <CloseIcon />
            </IconButton>  
          </Stack>
          
        </DialogTitle>
        <DialogContent>          
          <form onSubmit={handleSubmit(handleSubmitPlan)} >
          
          <TextField            
            fullWidth
            sx={{
              borderRadius: "7px",
              my: 1,
              backgroundColor: "#F6F6F6",
            }}                            
            label={'Title'}
            type="text"
            {...register('title')}
          />
          
          <ReactQuill 
            theme="snow" 
            className="my-2"
            name="desciption"
            value={getValues('description')}            
            onChange={value => {              
              setValue('description', value, {shouldDirty: true})
            }}
          />

          <FormControlLabel 
            control={
              <Checkbox            
                {...register('status')}
              />
              }
            label="Active"
          />
          
          <MyButton 
            sx={{ width: "100%", py: 1, mt: 1.5 }} 
            disabled={!isDirty}
            type="submit"
          >
            Save changes
          </MyButton>

          </form>          
          
        </DialogContent>        
      </Dialog>
  );
};

export default FaqEditModal;
