export const listSVG = () => {
 return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5 11.6667H11.6667V17.5H17.5V11.6667Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
 )   
}

export const videoSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_573_576)">
        <path d="M19.1666 5.83333L13.3333 9.99999L19.1666 14.1667V5.83333Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.6666 4.16667H2.49998C1.57951 4.16667 0.833313 4.91286 0.833313 5.83334V14.1667C0.833313 15.0871 1.57951 15.8333 2.49998 15.8333H11.6666C12.5871 15.8333 13.3333 15.0871 13.3333 14.1667V5.83334C13.3333 4.91286 12.5871 4.16667 11.6666 4.16667Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_573_576">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

export const audioSVG = () => {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 15V10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10V15" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.5 15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H15C14.558 17.5 14.134 17.3244 13.8215 17.0118C13.5089 16.6993 13.3333 16.2754 13.3333 15.8333V13.3333C13.3333 12.8913 13.5089 12.4674 13.8215 12.1548C14.134 11.8423 14.558 11.6667 15 11.6667H17.5V15.8333ZM2.5 15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H5C5.44203 17.5 5.86595 17.3244 6.17851 17.0118C6.49107 16.6993 6.66667 16.2754 6.66667 15.8333V13.3333C6.66667 12.8913 6.49107 12.4674 6.17851 12.1548C5.86595 11.8423 5.44203 11.6667 5 11.6667H2.5V15.8333Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        )
}

export const startSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99999 1.66667L12.575 6.88334L18.3333 7.72501L14.1667 11.7833L15.15 17.5167L9.99999 14.8083L4.84999 17.5167L5.83332 11.7833L1.66666 7.72501L7.42499 6.88334L9.99999 1.66667Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export const resaleSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_573_582)">
            <path d="M14.1667 0.833328L17.5 4.16666L14.1667 7.49999" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.5 9.16667V7.50001C2.5 6.61595 2.85119 5.7681 3.47631 5.14298C4.10143 4.51786 4.94928 4.16667 5.83333 4.16667H17.5" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.83333 19.1667L2.5 15.8333L5.83333 12.5" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 10.8333V12.5C17.5 13.384 17.1488 14.2319 16.5237 14.857C15.8986 15.4821 15.0507 15.8333 14.1667 15.8333H2.5" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_573_582">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export const filterSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3333 2.5H1.66663L8.33329 10.3833V15.8333L11.6666 17.5V10.3833L18.3333 2.5Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const shareSVG = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 6.66666C16.3807 6.66666 17.5 5.54737 17.5 4.16666C17.5 2.78594 16.3807 1.66666 15 1.66666C13.6193 1.66666 12.5 2.78594 12.5 4.16666C12.5 5.54737 13.6193 6.66666 15 6.66666Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 18.3333C16.3807 18.3333 17.5 17.2141 17.5 15.8333C17.5 14.4526 16.3807 13.3333 15 13.3333C13.6193 13.3333 12.5 14.4526 12.5 15.8333C12.5 17.2141 13.6193 18.3333 15 18.3333Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.1582 11.2583L12.8499 14.575" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.8415 5.42499L7.1582 8.74165" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export const favoriteSVG = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3666 3.84166C16.941 3.41583 16.4356 3.07803 15.8794 2.84757C15.3232 2.6171 14.727 2.49847 14.1249 2.49847C13.5229 2.49847 12.9267 2.6171 12.3705 2.84757C11.8143 3.07803 11.3089 3.41583 10.8833 3.84166L9.99994 4.725L9.1166 3.84166C8.25686 2.98192 7.0908 2.49892 5.87494 2.49892C4.65908 2.49892 3.49301 2.98192 2.63327 3.84166C1.77353 4.70141 1.29053 5.86747 1.29053 7.08333C1.29053 8.29919 1.77353 9.46525 2.63327 10.325L3.5166 11.2083L9.99994 17.6917L16.4833 11.2083L17.3666 10.325C17.7924 9.89937 18.1302 9.39401 18.3607 8.83779C18.5912 8.28158 18.7098 7.6854 18.7098 7.08333C18.7098 6.48126 18.5912 5.88508 18.3607 5.32887C18.1302 4.77265 17.7924 4.26729 17.3666 3.84166Z" stroke="#2A3538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
}

export const calendarSVG = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 3.33331H4.16667C3.24619 3.33331 2.5 4.07951 2.5 4.99998V16.6666C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6666V4.99998C17.5 4.07951 16.7538 3.33331 15.8333 3.33331Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.333 1.66669V5.00002" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66699 1.66669V5.00002" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 8.33331H17.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
}

export const numberSignSVG = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33301 7.5H16.6663" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.33301 12.5H16.6663" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33366 2.5L6.66699 17.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3337 2.5L11.667 17.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
}

export const playSVG = () => {
    return <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.666992 0.333328L16.667 11L0.666992 21.6667V0.333328Z" fill="#2A3538"/>
    </svg>
    
}
