import { useState } from "react";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, SvgIcon, TableFooter, TablePagination } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import useUserList from "src/hooks/useUserList";
import ConfirmModal from "../Modal/ConfirmModal";
import { SubscriptionStatus } from "src/utils/constant";

const UserManagement = () => {
    const [pagination, setPagination] = useState({
        page: 0,
        size: 5,
        search: ''
    })
    const {userList, count} = useUserList({pagination})
    const [anchorEl, setAnchorEl] = useState(null);
    const [removeUserConfirm, setRemoveUserConfirm] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
      }, []);
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const handleRemove = (user) => {
        setAnchorEl(null);
        setSelectedUser(user)
        setRemoveUserConfirm(true)
      };

    const subscriptionStatusLabel = (status) => {
        switch(status) {
            case SubscriptionStatus.APPROVAL_PENDING:
                return 'Pending';
            case SubscriptionStatus.ACTIVE:
                return 'Active'    
        }
    }

    const handleChangePage = (event, newPage) => {
        setPagination({...pagination, page: newPage});
      };
    
    const handleChangeRowsPerPage = (event) => {        
        setPagination({...pagination, size: event.target.value, page: 1})
    };

    const handleRemoveUser = () => {
        // TODO: remove user
    }

    // console.log(count)

    return (
        <>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>            
                    <TableCell >Name</TableCell>
                    <TableCell >Email</TableCell>
                    <TableCell >NFTs</TableCell>
                    <TableCell >Plan</TableCell>
                    <TableCell >Subscription Status</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {userList.map((user) => (
                    <TableRow
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {`${user.firstName} ${user.lastName}`}
                    </TableCell>
                    <TableCell >{user.email}</TableCell>
                    <TableCell >{user.totalNFT}</TableCell>              
                    <TableCell >{user.plan ? user.plan.name : ''}</TableCell>
                    <TableCell >{user.subscription ? subscriptionStatusLabel(user.subscription.status) : ''}</TableCell>
                    <TableCell >
                        <Button onClick={handleClick}>
                            <SvgIcon>
                                {/* { THREE_DOT_VERTICAL } */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                </svg>
                            </SvgIcon>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem onClick={() => handleRemove(user)}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Remove
                                </ListItemText>
                            </MenuItem>
                            {/* <Divider component="li" /> */}
                            
                        </Menu>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
                <TableFooter>
                    <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={count}
                    rowsPerPage={pagination.size}
                    page={pagination.page}
                    slotProps={{
                        select: {
                        inputProps: {
                            "aria-label": "rows per page",
                        },
                        native: true,
                        },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ></TablePagination>
                    </TableFooter>
            </Table>
        </TableContainer>
        <ConfirmModal
            open={removeUserConfirm}
            title="Remove User"
            message="Are you sure to remove user?"
            confirm={handleRemoveUser}
            cancel={() => setRemoveUserConfirm(false)}
        />
        </>        
      );
}

export default UserManagement