import { Box, Button, Checkbox, Grid, Stack, TextField, TextareaAutosize, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { AppContext } from "../Context";
import Loading from "../connectivityAssets/Loading";
import { post } from "../utils/fetchApis";
import usePlans from "../hooks/usePlans";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmPaypalSubscriptionModal from "../components/Modal/ConfirmPaypalSubscriptionModal";
import ErrorIcon from '@mui/icons-material/Error';
import PaymentSuccessModal from "../components/Modal/PaymentSuccessModal"
import moment from "moment";

const CustomButton = styled(Button)({
  textAlign: "center",
  background: "#F6F6F6",
  textTransform: "capitalize",
  padding: "8px 18px",
  borderRadius: "7px",
  width: "100%",
  "&:hover": {
    backgroundColor: "#6FDA44",
    color: "white",
  },
});

const textareStyle = {
  borderRadius: "7px",
  width: "100%",
  border: "1px solid #ccc"
  // backgroundColor: "#F6F6F6",
};

const inputStyle = {
  borderRadius: "7px",
  Input: {
    borderColor: "#6fda44",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#AAAAAA",
    },
  },

  // backgroundColor: "#F6F6F6",
};

const Checkout = () => {  
  
  const { userData, setTabIndex } = useContext(AppContext);  
  const [pagination] = useState({page: 1, size: 5, search: ''})
  const {plans} = usePlans({ pagination })
  const [plan, setPlan] = useState()
  const [searchParam] = useSearchParams()
  const options = useMemo(() => countryList().getData(), [])
  
  const { setAlertState } = useContext(AppContext);  
  const [loading, setLoading] = useState(false);
  const [confirmLink, setConfirmLink] = useState(false);
  const [errors, setErrors] = useState({})
  const [subscription, setSubscription] = useState([])
  const [refId, setRefId] = useState('')
  const [isConfirm, setIsConfirm] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)

  const [form, setForm] = useState({
    planId: "",
    firstname: "",
    lastname: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    comments: "",
    discountCode: ""
  })
  
  const [finalAmount, setFinalAmount] = useState("");

  const handleFormChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const validateForm = () => {
    let errors = {}

    if (!form.planId) {
      console.log(`You doesn't have planId`)
    }
    if (!form.firstname) {
      errors.firstname = "First name is required"
    }
    if (!form.lastname) {
      errors.lastname = "Last name is required"
    }
    if (!form.address) {
      errors.address = "Full address is required"
    }
    if (!form.country) {
      errors.country = "Country is required"
    }
    if (!form.state) {
      errors.state = "State is required"
    }
    if (!form.city) {
      errors.city = "City is required"
    }
    if (!form.zipcode) {
      errors.zipcode = "Zip code is required"
    }

    return errors
  }

  const handlePayment = async (e) => {

    try {             
      
      const errors = validateForm()
      if (Object.keys(errors).length) {
        setErrors(errors)
        return;
      }
      setLoading(true);
      
      const url = userData.subscription && userData.plan.name !== 'artist' ? 'subscription/upgrade' : 'subscription'
      
      const {success, data, message} = await post(url, {        
        ...form,
        country: form.country.value,
      })
      
      if (!success) {
        setAlertState({
          open: false,
          message,
          serverity: 'error',
        })
      }       

      setSubscription(data)
      setLoading(false)
      setConfirmLink(true)
    } catch (error) {
      console.error(error);
    }
  };    

  const approveLink = async() => {
    window.open(subscription.verifyLink, '_blank')    
  }    

  useEffect(() => {        
    console.log('params', searchParam.get('id'), plans)
    if (searchParam.get('success')) {
      setPaymentSuccess(true)    
    }    

    if (searchParam.get('id')) {
      const plan = plans.find(p => p.id == searchParam.get('id'))
      if (plan) {
        setPlan(plan)
        setForm({...form, planId: plan.id})
      }      
    }

    if (searchParam.get('subscription_id')) {
      setRefId(searchParam.get('subscription_id'))
    }
  }, [plans]);
  
  return (
    <>
      {loading && <Loading isLoading={loading} />}
      
      <Box py="20px" px={{ md: "200px", xs: "10px" }} backgroundColor="#eeeeee">

        <Stack my={6}>
          <Typography variant="h1" fontFamily="'Montserrat', sans-serif" textAlign={{ md: "left", xs: "center" }}>
            Checkout
          </Typography>
        </Stack>        
        

        <Grid component="form" container justifyContent="center" spacing={3}>
          <Grid item md={6} xs={12}>
            <Box backgroundColor="#ffffff" p={3}>
            <Typography my={2} variant="h3" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 24}}>
              Personal Information
            </Typography>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    FIRST NAME
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>                  
                
                <TextField                  
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  sx={inputStyle}
                  type="text"
                  placeholder="Your first name"
                  value={form.firstname}
                  name="firstname"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    LAST NAME
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>
                <TextField
                  error={!!errors.lastname}
                  helperText={errors.lastname}
                  sx={inputStyle}
                  placeholder="Your last name"
                  value={form.lastname}
                  name="lastname"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
            </Grid>            

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={12}>
              <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    FULL ADDRESS
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>
                <TextField
                  error={!!errors.address}
                  helperText={errors.address}
                  sx={inputStyle}
                  type="text"
                  placeholder="Your full address"
                  value={form.address}
                  name="address"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={6}>
              <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    COUNTRY
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>
                
                <Select options={options} value={form.country} onChange={value => setForm({...form, country: value})} />
              </Grid>
              <Grid item xs={12} md={6}>
              <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    STATE
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>
                <TextField 
                  error={!!errors.state}
                  helperText={errors.state}
                  sx={inputStyle} 
                  placeholder="Youir state" 
                  value={form.state} 
                  name="state"
                  size="small" 
                  fullWidth 
                  onChange={handleFormChange}
                  required />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={6}>
              <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    CITY
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>
                <TextField
                  error={!!errors.city}
                  helperText={errors.city}
                  sx={inputStyle}
                  type="text"
                  placeholder="Your city"
                  value={form.city}
                  name="city"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <Stack direction="row">
                  <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                    ZIP CODE
                  </Typography>
                  <img className="mb-1" src="/assets/images/form-mandatory.svg" width={16} height={16} alt="" /> 
                </Stack>
                <TextField
                  error={!!errors.zipcode}
                  helperText={errors.zipcode}
                  sx={inputStyle}
                  placeholder="Your zipcode"
                  value={form.zipcode}
                  name="zipcode"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" sx={{fontSize: 14}}>
                  ADDITIONAL COMMENTS
                </Typography>
                <TextareaAutosize 
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  minRows={11} 
                  style={textareStyle} 
                  name="comments"
                  value={form.comments} 
                  onChange={handleFormChange} />
              </Grid>
            </Grid>
            </Box>
            
          </Grid>

          <Grid item md={6} xs={12}>
            <Box backgroundColor="#ffffff">
              <Box
                style={{
                  boxShadow: "1px 1px 8px grey",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}                
                pb={2}
              >
                <Typography
                  p={2}
                  variant="h3"
                  fontFamily="'Montserrat', sans-serif"
                  textAlign="center"
                  sx={{
                    backgroundColor: "#2A3538",
                    color: "white",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Order Summary
                </Typography>

                <Typography p={2} variant="subtitle1" fontFamily="'Montserrat', sans-serif" sx={{ textTransform: 'capitalize' }}>
                  {plan?.name}
                </Typography>

                <Box>
                  {plan?.description?.map((it, index) => {
                    return (
                      <Stack direction="row" alignItems="center" px={2} key={index}>
                        <CheckCircleIcon sx={{ color: "#6FDA44" }} />
                         <Typography 
                          ml="8px"
                          variant="overline"
                          fontFamily="'Montserrat', sans-serif" 
                          fontWeight={"400 !important"}
                        >
                          {it}
                        </Typography>
                      </Stack>
                    );
                  })}                  
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{
                    xs: "row",
                    md: "row",
                  }}
                  rowGap={"10px"}
                  alignItems={"center"}
                  mt={2}
                  px={2}
                > 
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{
                    xs: "row",
                    md: "row",
                  }}
                  rowGap={"10px"}
                  alignItems={"center"}
                  mt={2}
                  px={2}
                  gap={"10px"}
                >                  
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{
                    xs: "row",
                    md: "row",
                  }}
                  rowGap={"10px"}
                  alignItems={"center"}                  
                  px={2}
                >
                  <Box width="100%">
                    <Stack direction='row' alignItems="center">
                      <Typography variant="h3" fontFamily="'Montserrat', sans-serif" fontSize={20}>
                        Total
                      </Typography>

                      <Typography 
                        ml="auto"
                        variant="h3"
                        fontFamily="'Montserrat', sans-serif" >
                        <span style={{ fontWeight: "bolder" }}> {finalAmount || finalAmount === 0 ? `$${finalAmount}` : plan?.price} / </span>
                        month
                      </Typography>
                    </Stack>                                      
                    <Stack direction='row' alignItems="center" mt={1}>
                      <Typography fontFamily="'Montserrat', sans-serif" textAlign="left" style={{ color: "#333333", fontSize: "14px" }}>
                        First renewal
                      </Typography>
                      <Typography 
                        ml="auto"
                        fontFamily="'Montserrat', sans-serif" 
                        style={{ color: "#333333", fontSize: "14px" }}>
                        {moment().add(1, 'month').format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                    </Stack>
                  </Box>
                </Box>

                <Stack px={2} mt={3}>
                  <FormControlLabel
                    control={<Checkbox checked={isConfirm} onChange={(e) => setIsConfirm(e.target.checked)} />}
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontWeight: 600,
                        fontSize: "14px !important",
                      },
                    }}
                    label={
                      <Typography sx={{ fontSize: "14px !important" }}>
                        I agree to the{" "}
                        <a href="/upload-term-conditions" target="_blank" style={{ fontWeight: 700, fontSize: "14px" }}>
                          Term and Conditions Policy
                        </a>
                      </Typography>
                    }
                  />
                </Stack>                

                <Box py={1} px={2}>                  
                  <CustomButton
                    sx={{                      
                      width: "100%",
                      fontFamily: "'Montserrat', sans-serif",
                      fontWeight: "bold",
                      backgroundColor: "#6fda44",
                      color: "#333333",
                    }}
                    onClick={handlePayment}                    
                    disabled={loading || !isConfirm}
                  >
                    Place Order
                  </CustomButton>
                </Box>

                <Stack direction="row" alignItems="center" px={2} mt={3}>
                  <ErrorIcon color="#333333"/>
                  <Typography sx={{ fontSize: "14px !important", color: "#333333" }} ml={1}>
                    You will be redirected to another page to complete your subscription
                  </Typography>
                </Stack>
                
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <ConfirmPaypalSubscriptionModal
        open={confirmLink}
        cancel={_ => setConfirmLink(false)}
        confirm={approveLink}
      />

      <PaymentSuccessModal
        open={paymentSuccess}
        id={refId}
        amount={plan?.price}
        paymentTime={userData?.subscription ? moment(userData.subscription['createdAt']).format('DD MMM YYYY, h:mm') : ''}
        renewal={moment().add(1, 'month').format('DD MMM YYYY')}
        cancel={_ => setPaymentSuccess(false)}        
      >        
      </PaymentSuccessModal>
    </>
  );
};

export default Checkout;
