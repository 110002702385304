import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from "react-hook-form";
import MyButton from "../Buttonone";
import { useState } from "react";

const PlanEditModal = ({ action, plan, open, save, cancel }) => {    
  const dialogInputFields = [
    {
      type: "text",
      placeholder: "Plan name",
      name: "name",
    },
    {
      type: "number",
      placeholder: "Price",
      name: "price",
    },
    {
      type: "number",
      placeholder: "NFT limit",
      name: "amount",
    },
    {
      type: "text",
      placeholder: "Interval",
      name: "interval",
    },
    {
      type: "text",
      placeholder: "Description",
      name: "description",
    },
  ];
  const {
    register,
    handleSubmit,
    setValue,    
    formState: { errors, isDirty, dirtyFields },    
  } = useForm({
    defaultValues: {
      name: plan && plan.name ? plan.name : '',
      price: plan && plan.price ? plan.price : '',
      amount: plan && plan.amount ? plan.amount : '',
      interval: plan && plan.interval ? plan.interval : '',
      description: plan && plan.description ? plan.description : '',
    }
  });  

  const handleSubmitPlan = (formValue) => {
    console.log(dirtyFields, formValue)
    
    if (action === 'add') {
      
      formValue['description'] = formValue['description'].split(',')
      save(formValue)
    } else {
      const dirtyValues = {}
      Object.keys(dirtyFields).map(key => {
        if (key === 'description') 
          dirtyValues[key] = formValue[key].split(',')
        else
          dirtyValues[key] = formValue[key]
      })
      
      save(dirtyValues)
    } 
  }
  
  // console.log(plan)
  
  return (
    <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" alignItems="center">
            {action} plan
            <IconButton 
              onClick={cancel} 
              sx={{ marginLeft: "auto" }} 
              color="primary"               
              component="label">
              <CloseIcon />
            </IconButton>  
          </Stack>
          
        </DialogTitle>
        <DialogContent>          
          <form onSubmit={handleSubmit(handleSubmitPlan)} >
          {dialogInputFields.map((item, i) => (
            <TextField
              key={i}
              fullWidth
              sx={{
                borderRadius: "7px",
                my: 1,
                backgroundColor: "#F6F6F6",
              }}                            
              label={item.placeholder}
              type={item.type}
              {...register(item?.name)}
            />
          ))}
          <MyButton 
            sx={{ width: "100%", py: 1, mt: 1.5 }} 
            disabled={!isDirty}
            type="submit"
          >
            Save changes
          </MyButton>

          </form>          
          
        </DialogContent>        
      </Dialog>
  );
};

export default PlanEditModal;
